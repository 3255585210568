<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">Form Edit Industri</h6>
          </b-col>
          <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
            <base-button @click="back()">Kembali</base-button>
          </b-col>
        </b-row>
      </base-header>
  
      <b-container fluid class="mt--6">
        <b-row>
          <!-- akun -->
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
                <div class="card-body">
                  <form>
                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Jenis Industri</label>
                      <div class="col-lg-9">
                        <base-input name="Jenis Industri">
                          <el-select v-model="catchInput.company_type_uid">
                            <el-option
                              v-for="option in provideSelect.companyType"
                              :key="option.uid"
                              :label="option.name"
                              :value="option.uid"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Permohonan Koneksi</label>
                        <div class="col-lg-9">
                        <base-input name="File Permohonan Koneksi">
                            <file-input
                            accept="application/pdf"
                            ref="company_connection_request"
                            @change="uploadFile('company_connection_request', 'company_connection_request')"
                            ></file-input>
                        </base-input>
                        <small class="d-block mt--3 text-justify">
                            Surat permohonan pendaftaran koneksi sparing kepada
                            Direktur Pengendalian Pencemaran Air.
                        </small>
                        </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Nama Industri</label>
                      <div class="col-lg-9">
                        <base-input
                          name="Alamat"
                          v-model="catchInput.name"
                        ></base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Surat Number</label>
                      <div class="col-lg-9">
                        <base-input
                          name="sumberLimbah"
                          v-model="catchInput.siup_number"
                        ></base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-lg-3 col-form-label form-control-label">Penanggungjawab SIUP</label>
                        <div class="col-lg-9">
                            <base-input
                                name="Alamat"
                                v-model="catchInput.siup_on_behalf"
                            ></base-input>
                        </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label"
                        >Lampiran SIUP</label
                      >
                      <div class="col-lg-9">
                        <base-input name="Lampiran SIUP">
                          <file-input
                            accept="application/pdf"
                            ref="company_siup_attachment"
                            @change="uploadFile('company_siup_attachment', 'company_siup_attachment')"
                          ></file-input>
                        </base-input>
                            <a
                              :href="catchInput.siup_attachment"
                              target="blank"
                              v-if="catchInput.siup_attachment"
                            >Lihat File Sebelumnya</a>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Telepon</label>
                      <div class="col-lg-9">
                        <base-input
                          name="teknikPengolahanLimbah"
                          v-model="catchInput.phone"
                        ></base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Email</label>
                      <div class="col-lg-9">
                        <base-input
                          name="nomorIzin"
                          v-model="catchInput.email"
                        ></base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Alamat</label>
                      <div class="col-lg-9">
                        <base-input
                          name="intensiPenerbitIzin"
                          v-model="catchInput.address"
                        ></base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Jalan</label>
                      <div class="col-lg-9">
                        <base-input
                          name="tanggalIzin"
                          v-model="catchInput.street"
                        ></base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Provinsi</label>
                      <div class="col-lg-9">
                        <base-input name="Provinsi" rules="required">
                          <el-select v-model="catchInput.province_id" @change="getCityDepentOnProvince(catchInput.province_id)" filterable>
                            <el-option
                              v-for="option in provideSelect.provinsi"
                              :key="option.id"
                              :label="option.name"
                              :value="option.id"
                            ></el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-lg-3 col-form-label form-control-label">Kabupaten / Kota</label>
                      <div class="col-lg-9">
                        <base-input name="Kabupaten / Kota" rules="required">
                          <select class="form-control" v-model="catchInput.regency_id">
                            <option
                              v-for="(option, idx) in provideSelect.kabupatenKota"
                              :key="idx"
                              :value="option.id"
                            >
                              {{ option.name }}
                            </option>
                          </select>
                        </base-input>
                      </div>
                    </div>
  
                    <div class="form-group row">
                    <label class="col-lg-3 col-form-label form-control-label"></label>
                    <div class="col-lg-9">
                      <base-button type="primary" class="w-25" @click="submitData">Update</base-button>
                    </div>
                  </div>
                  </form>
                </div>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  <script>
  import API from "@/api/base_url.js";
  import FileInput from "@/components/Inputs/FileInput";
  import Swal from "sweetalert2";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import BaseHeader from "@/components/BaseHeader";
  import { Select, Option } from "element-ui";

  
  export default {
    components: {
      FileInput,
      BaseHeader,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    mounted() {
        this.getSelectFormValue();
        this.getCompanyType()
        this.getData()
        console.log(this.$route.params.id);
    },
    data() {
      return {
        provideSelect: {
            provinsi: [],
            kabupatenKota: [],
            daftarPerusahaan: [],
            tmp: [],
            uploadChoosen: null,
            companyType: []
        },
        jenis: [{ name: "Pengolahan Minyak Bumi" }, { name: "Industri Minyak Sawit" }],
        catchInput: {
            company_type_uid:'',
            connection_request:'',
            name:'',
            siup_number:'',
            siup_on_behalf:'',
            siup_attachment:'',
            phone:'',
            email:'',
            address:'',
            street:'',
            province_id:'',
            regency_id:'',
            district_id:'',
            village_id:''
        }
      };
    },
    methods: {
    getData() {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        console.log(this.$route.params.id);
        API.get(`web/master/company?uid=${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
            console.log(content.data);
            this.catchInput.company_type_uid = content.data.company_type_id,
            this.catchInput.connection_request = content.data.connection_request,
            this.catchInput.name = content.data.name,
            this.catchInput.siup_number = content.data.siup_number,
            this.catchInput.siup_on_behalf = content.data.siup_on_behalf,
            this.catchInput.siup_attachment = content.data.siup_attachment,
            this.catchInput.phone = content.data.phone,
            this.catchInput.email = content.data.email,
            this.catchInput.address = content.data.address,
            this.catchInput.street = content.data.street,
            this.catchInput.province_id = content.data.province_id,
            this.getCityDepentOnProvince(content.data.province_id)
            this.catchInput.regency_id = content.data.regency_id
            this.catchInput.district_id = content.data.district_id,
            this.catchInput.village_id = content.data.village_id
        })
        .catch((err) => {
            console.log(err);
        })
    },
      back() {
        this.$router.push("/admin-company");
        },
        getSelectFormValue() {
        API.get("region/provinces")
            .then(({ data: content }) => {
            this.provideSelect.provinsi = content.data;
            })
            .catch((err) => {
            console.log(err);
            });
        },
        getCityDepentOnProvince(data) {
        this.provideSelect.kabupatenKota = [];
        API.get(`region/regencies/province/${data}`)
            .then(({ data: content }) => {
            this.provideSelect.kabupatenKota = content.data;
            })
            .catch((err) => {
            console.log(err);
            });
        },
        getCompanyType() {
        API.get('web/front/registration-company-type')
            .then(({data:content}) => {
            this.provideSelect.companyType = content.data
            })
            .catch((err) => {
            console.log(err);
            })
        },
        uploadFile(category, field) {
            this.catchInput.siup_attachment = this.$refs[field].files[0] 
        // this.formData.append(field, this.$refs[field].files[0]);
        },
        submitData() {
            let payload = {
                company_type_uid:this.catchInput.company_type_uid,
                connection_request:'',
                name:this.catchInput.name,
                siup_number:this.catchInput.siup_number,
                siup_on_behalf:this.catchInput.siup_on_behalf,
                siup_attachment:'',
                phone:this.catchInput.phone,
                email:this.catchInput.email,
                address:this.catchInput.address,
                street:this.catchInput.street,
                province_id:this.catchInput.province_id,
                regency_id:this.catchInput.regency_id,
                district_id:'',
                village_id:''
            }

            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

            Swal.fire({
                title: 'Edit Data Perusahaan?',
                showDenyButton: true,
                confirmButtonText: 'Edit',
                denyButtonText: `Batal`,
                }).then((result) => {
                if (result.isConfirmed) {
                    API.post(`web/master/company`, payload, { headers })
                        .then(({ data: content }) => {
                        console.log(content);
                        Swal.fire('Perubahan data berhasil', 'Perusahaan berhasil merubah data', 'success')
                        //   this.$router.push("/login");
                        })
                        .catch((err) => {
                        Swal.fire('Perubahan data gagal', `${err.response.data.error}`, 'error')
                        console.log(err);
                    });
                } else if (result.isDenied) {
                    Swal.fire('Perubahan data dibatalkan', '', 'info')
                }
            })
        }
    },
  };
  </script>
  <style></style>
  